import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { APP_CONFIG, AppConfig } from '@core/models';
import { NgxCaptchaModule, InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-captcha',
  standalone: true,
  imports: [NgxCaptchaModule],
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaComponent implements AfterViewInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public siteKey: string;
  public isBrowser: boolean;

  @ViewChild('captchaElem') captchaElem?: InvisibleReCaptchaComponent;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() success: EventEmitter<string> = new EventEmitter<string>();

  constructor(@Inject(PLATFORM_ID) private platformId: object, @Inject(APP_CONFIG) private config: AppConfig) {
    this.siteKey = this.config.captchaSiteKey;
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit(): void {
    this.captchaElem?.success.pipe(takeUntil(this.stop$)).subscribe((response) => {
      this.success.next(response);
      this.captchaElem?.resetCaptcha();
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  execute(): void {
    this.captchaElem?.execute();
  }

  resetCaptcha(): void {
    this.captchaElem?.resetCaptcha();
  }
}
