import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken('APP-CONFIG');
export const SSR_APP_CONFIG = new InjectionToken('SSR-APP-CONFIG');

export interface AppConfig {
  production: boolean;
  cookieFirstId: string;
  russianCookieFirstId: string;
  googleAnalyticsUACode: string;
  googleAnalytics4Code: string;
  googleTagManagementCode: string;
  googleAdsCode: string;
  googleSearchConsoleId: string;
  hotJarCode: string;
  butterApiUrl: string;
  butterCMSQueryParam: string;
  environmentName: string;
  siteName: string;
  apiUrl: string;
  captchaSiteKey: string;
  teqSiteId: string;
  itvfApiUrl: string;
  storageUrl: string;
  chatbotKey: string;
  russianDomain?: string;
}
